/* eslint-disable @typescript-eslint/typedef */
import React, { useEffect, useState } from 'react'
import { Button, Wrapper } from './style'
import useDomReady from 'src/hooks/window/useDomReady'
import videos from './videos.json'
import { useSnackBar } from '@interco/inter-ui/components/SnackBar'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type VideosType = {
  [key: string]: string;
};

const typedVideos: VideosType = videos

const videosIndex: { [key: number]: string } = {
  0: "14",
  1: "18",
  2: "33",
  3: "68",
  4: "77",
  5: "95",
}

const correctVideoID = +videosIndex[Math.floor(Math.random() * 6)]

export default function Validation () {
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const [ videoEnded, setVideoEnded ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ confirmationNumbers, setConfirmationNumbers ] = useState<number[]>([])
  const { showSnackBar } = useSnackBar()
  const videoElement = document.getElementById('libras-video') as HTMLVideoElement | null

  function videoEndedHandler () {
    if (!videoElement) return
    if (Math.floor(videoElement.currentTime) >= 46) {
      setVideoEnded(true)
    }
  }

  useEffect(() => {
    const result = Array.from(Array(5).keys()).map(() => Math.floor(Math.random() * 99))
    if (result.indexOf(correctVideoID) !== -1) {
      result.splice(6, 0, 45)
    } else {
      result.splice(Math.floor(Math.random() * 6), 0, correctVideoID)
    }
    setConfirmationNumbers(result)
  }, [])

  useEffect(() => {
    if (domReady) {
      if (videoElement) {
        videoElement.playbackRate = 1.25
        videoElement.addEventListener('timeupdate', videoEndedHandler, false)
      }
    }
  }, [ domReady ])

  function handleVideoClick (item: number) {
    if (domReady) {
      if (item === correctVideoID) {
        window.location.href = 'https://icom.app/inter'
        sendDatalayerEvent({
          section: 'dobra_01',
          element_action: 'click button',
          element_name: item.toString(),
          section_name: 'Orientações importantes!',
          redirect_url: 'https://icom.app/inter',
        })
      } else {
        sendDatalayerEvent({
          section: 'dobra_01',
          element_action: 'click button',
          element_name: item.toString(),
          section_name: 'Orientações importantes!',
          redirect_url: 'https://inter.co/atendimento-em-libras/',
        })
        setTimeout(() => {
          window.location.href = `https://inter.co/atendimento-em-libras/?utm_source=site&utm_medium=${item}`
        }, 3000)
        setError(true)
        showSnackBar({
          title: 'Número incorreto',
          content: 'Você será redirecionado para a página inicial',
          type: 'ERROR',
        })
      }
    }
  }

  return (
    <Wrapper>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='fs-24 lh-28 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>Orientações importantes!</h1>
            <p className='fs-14 lh-18 fs-lg-18 lh-lg-22'>Para garantir uma boa experiência, preparamos algumas dicas! No vídeo abaixo vamos te explicar como funciona nosso atendimento em libras. Vamos começar!?</p>
          </div>
          <div className='col-12'>
            <video
              controls
              id='libras-video'
              width={width < 768 ? '100%' : '80%'}
              height='auto'
              preload='auto'
              muted
              autoPlay
              playsInline
            >
              <source src={typedVideos[correctVideoID.toString()]} type='video/mp4' />
            </video>
          </div>
          <div className='col-12'>
            {
              confirmationNumbers.map((item: number) => (
                <Button key={item} disabled={!videoEnded || error} onClick={() => handleVideoClick(item)}>
                  {item}
                </Button>
              ))
            }
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
