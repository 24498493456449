import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

type ButtonType = {
  disabled: boolean;
}

export const Wrapper = styled.section`
  background-color: ${grayscale[100]};
  height: fit-content;

  @media ${device.tablet} {
    text-align: center;
  }
  h1 {
    font-family: 'Sora', sans-serif;
  }
`

export const Button = styled.button<ButtonType>`
  width: 20%;
  margin: 20px 20px;
  background-color: ${({ disabled }: ButtonType) => disabled ? grayscale[200] : orange.extra};
  border-radius: 8px;
  color: white;
  font-family: 'Sora', sans-serif;
  font-size: 32px;
  font-weight: 600;

  @media ${device.tablet} {
    width: 25%;
  }
`
