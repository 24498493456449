import axios from 'axios'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import React, { ChangeEvent, MouseEvent, useState } from 'react'
import { UseFormMethods, useForm } from 'react-hook-form'
import errorImg from 'src/assets/images/shared/erro-pig.png'
import AcceptTerms from 'src/components/AcceptTerms'
import * as URLs from 'src/config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Button, Card } from './../Form/style'
import useDomReady from 'src/hooks/window/useDomReady'
interface IFormValues {
  customerName: string;
  phone: string;
  document: string;
}

const AccessFormLibras = ({ setIsOpenValidation }: {setIsOpenValidation: Function}) => {
  const [ accept, setAccept ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const utmLinks = domReady && window.location.search

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setLoading(false)
  }

  const formSubmit = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      document: data.document.replace(/\D/g, ''),
      authorize: accept,
      utm: utmLinks,
    }
    try {
      await axios.post(`${URLs.WEB_API_LP_ATENDIMENTO_LIBRAS}/api/v1/canais/case-hearing-impaired`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setAccept(false)
      setLoading(false)
      setIsOpenValidation(true)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Atendimento em Libras',
        element_name: 'Continuar',
        element_action: 'submit',
        step: 'success',
      })
    } catch (err) {
      setLoading(false)
      setError(true)
    }
  }

  return (
    <>
      { error && (
        <div className='text-center'>
          <h4 className='fs-18 lh-22 mb-4'>Ah não! Ocorreu um erro com a sua solicitação...</h4>
          <img src={errorImg} alt='Imagem de erro' className='ml-n3' />
          <p className='text-grayscale--400 fs-14 lh-17 py-3'>
            Verifique a sua conexão com a internet e tente novamente.
            <button
              title='Clique aqui'
              className='fw-600 border-0 bg-transparent pl-1'
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                handleReturn(e)
              }}
            > Clique aqui
            </button> para voltar.
          </p>
        </div>
      )}
      <Card className={`${error ? 'd-none' : 'd-flex align-items-center'}`}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className={`col-12 pl-1 pr-0 my-1 ${errors.customerName && 'error'}`}>
            <label className='text-grayscale--400 fw-600 fs-12 lh-14 d-block'>Nome</label>
            <input
              placeholder='Digite seu nome completo'
              type='text'
              name='customerName'
              maxLength={80}
              ref={register({
              required: 'Por favor, digite seu nome completo',
              validate: {
                isName: (value: string) => Validations.validFullName(value) || 'Por favor, digite seu nome completo',
              },
            })}
            />
            {errors.customerName && <p className='fs-12 mb-0 text-right'>{errors.customerName.message}</p>}
          </div>
          <div className={`col-12 pl-1 pr-0 mt-3 mb-1 ${errors.phone && 'error'}`}>
            <label htmlFor='phone' className='text-grayscale--400 fw-600 fs-12 lh-14 d-block'>Telefone</label>
            <input
              ref={register({
                  required: 'Telefone obrigatório',
                  pattern: {
                    value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                    message: 'Telefone inválido',
                  },
                })}
              name='phone'
              id='phone'
              type='tel'
              placeholder='(00) 0000-0000'
              maxLength={15}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('phone', Masks.MaskPHONE(event.currentTarget.value))}
            />
            { errors.phone && <p className='fs-12 mb-0 text-right'>{errors.phone.message}</p> }
          </div>
          <div className={`col-12 pl-1 pr-0 mt-3 mb-1 ${errors.document && 'error'}`}>
            <label className='text-grayscale--400 fw-600 fs-12 lh-14 d-block'>CPF</label>
            <input
              ref={register({
                required: 'Digite um CPF válido',
                validate: {
                  isCpf: (value: string) => Validations.cpforcnpj(value) || 'CPF Inválido',
                },
              })}
              placeholder='Digite seu CPF'
              type='text'
              name='document'
              onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('document', Masks.MaskCPF(event.currentTarget.value))}
            />
            {errors.document && <p className='fs-12 mb-0 text-right'>{errors.document.message}</p>}
          </div>
          <div className='col-12 pl-1 pr-0 mt-3 mb-4'>
            <AcceptTerms
              accept={accept}
              setAccept={setAccept}
              styles='fs-12 lh-15 text-grayscale--400 fw-400 mt-2 mb-3'
              name='aceite'
              label='Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a '
            />
            <Button
              type='submit'
              title='Continuar'
              disabled={!accept || loading}
            >
              {loading ? 'Enviando...' : 'Continuar' }
            </Button>
          </div>
        </form>
      </Card>
    </>
  )
}

export default AccessFormLibras
